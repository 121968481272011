import { setParamSeparator } from './format';
import { isDealerImage, hasImpolicy } from './helpers/images';

/**
 * Determines that image URL meets criteria for resize adjustments
 * Returns a boolean that checks for dealer.com image domain and no impolicy param included
 * @param {string} imageUrl - A URL string value
 */
export const isResizable = (imageUrl) =>
	isDealerImage(imageUrl) && !hasImpolicy(imageUrl);

/**
 * Determines if image URL and image location allow responsive image
 * Returns a boolean that checks `imageLocation` and if it should be resizable
 * @param {string} imageUrl - A URL string value
 * @param {string} imageLocation - A string value for defining foreground param configuraiton
 */
export const isResponsiveImageType = (imageUrl, imageLocation) => {
	return imageLocation !== 'foreground' && isDealerImage(imageUrl);
};

/**
 * Formats url of the imageUrl based on height and width parameters
 * Returns resized image URL
 * @param {string} imageUrl - A URL string value
 * @param {number} imageHeight - A number value for height
 * @param {number} imageWidth - A number value for width
 * @param {string} imageLocation - A string value for defining foreground param configuraiton
 */
export const setImageUrl = (
	imageUrl,
	imageHeight,
	imageWidth,
	imageLocation
) => {
	if (imageLocation === 'foreground' && isDealerImage(imageUrl)) {
		const resizeMethod =
			imageHeight && imageWidth ? 'downsize_crop' : 'downsize';

		// If imageHeight is not set, return early so the unveil/resizer
		// tool will take over and set the appropriate dimensions.
		if (!imageHeight) {
			return imageUrl;
		}

		// 'impolicy' parameter triggers Akamai resizing
		let resizedImage = `${imageUrl}${setParamSeparator(
			imageUrl
		)}impolicy=${resizeMethod}`;

		if (imageHeight) {
			resizedImage = `${resizedImage}&h=${imageHeight}`;
		}
		if (imageWidth) {
			resizedImage = `${resizedImage}&w=${imageWidth}`;
		}

		return resizedImage;
	} else if (imageUrl) {
		return imageUrl;
	}

	return null;
};

/**
 * default classlist added to all responsive background <img>
 */
export const defaultImgClassList = ['responsive-image', 'd-none'];

/**
 * Build the loading props for a responsive image based on `isPriorityContent`
 * @param {GetImgLoadingPropsArgs} param0
 * @returns {ImageLoadingProps}
 */
export const getImgLoadingProps = ({
	isPriorityContent,
	resizeImageUrl,
	classList
}) => {
	return {
		// If priority content, don't use data-src to lazy load content
		...{
			...(isPriorityContent ? {} : { 'data-src': resizeImageUrl })
		},
		// If priority content, load the image instead of blank
		src: isPriorityContent
			? resizeImageUrl
			: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
		// If NOT priority content, add lazy-image to classList, then join.
		className: [
			isPriorityContent ? '' : 'lazy-widgets-content-cta-image',
			...classList
		].join(' ')
	};
};
